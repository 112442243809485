import { memo } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import "./social-media-section.scss";

const GITHUB_LINK = "https://github.com/JermyTan";
const INSTAGRAM_LINK = "https://www.instagram.com/happyboiboi/";
const FACEBOOK_LINK = "https://www.facebook.com/TanKaiQunJeremy";
const LINKEDIN_LINK = "https://www.linkedin.com/in/jermy97/";
const EMAIL_LINK = "tan_kai_qun97@yahoo.com.sg";

function SocialMediaSection() {
  return (
    <div className="social-media-section">
      <Button
        as="a"
        href={GITHUB_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="btn-round btn-icon"
        id="github"
        size="lg"
        aria-label="github"
      >
        <i className="fab fa-github" />
      </Button>
      <UncontrolledTooltip delay={0} target="github">
        Follow me on Github
      </UncontrolledTooltip>

      <Button
        as="a"
        href={INSTAGRAM_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="btn-round btn-icon"
        id="instagram"
        size="lg"
        aria-label="instagram"
      >
        <i className="fab fa-instagram" />
      </Button>
      <UncontrolledTooltip delay={0} target="instagram">
        Follow me on Instagram
      </UncontrolledTooltip>

      <Button
        as="a"
        href={FACEBOOK_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="btn-round btn-icon"
        id="facebook"
        size="lg"
        aria-label="facebook"
      >
        <i className="fab fa-facebook" />
      </Button>
      <UncontrolledTooltip delay={0} target="facebook">
        Add me on Facebook
      </UncontrolledTooltip>

      <Button
        as="a"
        href={LINKEDIN_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="btn-round btn-icon"
        id="linkedin"
        size="lg"
        aria-label="linkedin"
      >
        <i className="fab fa-linkedin" />
      </Button>
      <UncontrolledTooltip delay={0} target="linkedin">
        Connect with me on Linkedin
      </UncontrolledTooltip>

      <Button
        as="a"
        href={`mailto:${EMAIL_LINK}`}
        className="btn-round btn-icon"
        id="email"
        size="lg"
        aria-label="email"
      >
        <i className="fab fas fa-envelope" />
      </Button>
      <UncontrolledTooltip delay={0} target="email">
        Email me
      </UncontrolledTooltip>
    </div>
  );
}

export default memo(SocialMediaSection);
