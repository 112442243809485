import { useState, memo, RefCallback } from "react";
import clsx from "clsx";
import { Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";
import { saveAs } from "file-saver";
import { Document, Page, pdfjs } from "react-pdf";
import { useMeasure } from "react-use";
import PlaceholderWrapper from "../placeholder-wrapper";
import FullscreenButton from "../fullscreen-button";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./pdf-viewer.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export type Pdf = {
  name: string;
  data: string | File;
};

type Props = {
  pdf: Pdf;
};

const documentOptions = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps`,
  cMapPacked: true,
};

function PdfViewer({ pdf: { name, data } }: Props) {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const roundedZoom = Math.round(zoom * 10) / 10;
  const [ref, { width }] = useMeasure<HTMLElement>();
  const [loaded, setLoaded] = useState(false);

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setLoaded(true);
    setNumPages(numPages);
    setPageNumber(1);
  };

  return (
    <div
      ref={ref as RefCallback<HTMLElement>}
      className={clsx(loaded && "pdf-viewer")}
    >
      {loaded && (
        <div className="pdf-toolbar horizontal-space-margin">
          <ButtonGroup className="toolbar-item-group">
            <Button
              className="toolbar-button"
              disabled={pageNumber <= 1}
              onClick={() => setPageNumber(pageNumber - 1)}
              id="previous-page"
            >
              <i className="fas fa-chevron-left" />
            </Button>
            <UncontrolledTooltip
              popperClassName="inverted-tooltip"
              delay={0}
              target="previous-page"
            >
              Previous page
            </UncontrolledTooltip>
            <Button className="toolbar-button button-label" disabled outline>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </Button>
            <Button
              className="toolbar-button"
              disabled={pageNumber >= numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
              id="next-page"
            >
              <i className="fas fa-chevron-right" />
            </Button>
            <UncontrolledTooltip
              popperClassName="inverted-tooltip"
              delay={0}
              target="next-page"
            >
              Next page
            </UncontrolledTooltip>
          </ButtonGroup>

          <ButtonGroup className="toolbar-item-group">
            <Button
              className="toolbar-button"
              disabled={roundedZoom <= 0.1}
              onClick={() => setZoom(roundedZoom - 0.1)}
              id="zoom-out"
            >
              <i className="fas fa-search-minus" />
            </Button>
            <UncontrolledTooltip
              popperClassName="inverted-tooltip"
              delay={0}
              target="zoom-out"
            >
              Zoom out
            </UncontrolledTooltip>
            <Button className="toolbar-button button-label" disabled outline>
              {`${(roundedZoom * 100).toFixed(0)}%`}
            </Button>
            <Button
              className="toolbar-button"
              disabled={zoom >= 2}
              onClick={() => setZoom(roundedZoom + 0.1)}
              id="zoom-in"
            >
              <i className="fas fa-search-plus" />
            </Button>
            <UncontrolledTooltip
              popperClassName="inverted-tooltip"
              delay={0}
              target="zoom-in"
            >
              Zoom in
            </UncontrolledTooltip>
          </ButtonGroup>

          <Button
            className="toolbar-button toolbar-item"
            id="download-file"
            onClick={() => saveAs(data, name)}
          >
            <i className="fas fa-file-download" />
          </Button>
          <UncontrolledTooltip
            popperClassName="inverted-tooltip"
            delay={0}
            target="download-file"
          >
            Download file
          </UncontrolledTooltip>

          <FullscreenButton
            className="toolbar-button toolbar-item"
            hideOnFullscreen
          />
        </div>
      )}

      <Document
        file={data}
        onLoadSuccess={onLoadSuccess}
        externalLinkTarget="_blank"
        options={documentOptions}
        error={
          <PlaceholderWrapper
            placeholder
            showDefaultMessage
            defaultMessage="Failed to load PDF file"
          />
        }
        loading={
          <PlaceholderWrapper
            placeholder
            loading
            loadingMessage="Loading PDF file"
          />
        }
      >
        <Page
          pageNumber={pageNumber}
          scale={zoom}
          width={Math.min(width, 800)}
          renderAnnotationLayer
          renderTextLayer
          renderMode="svg"
        />
      </Document>
    </div>
  );
}

export default memo(PdfViewer);
