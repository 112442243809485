import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { PATH_TITLE_MAPPING } from "../../constants";

function GaPageViewManager() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname + search,
      title: PATH_TITLE_MAPPING[pathname],
    });
    // console.log(PATH_TITLE_MAPPING[pathname], pathname + search);
  }, [pathname, search]);

  return null;
}

export default GaPageViewManager;
