import { memo } from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import seaLogoPng from "../../assets/media/sea-logo.png";
import seaLogoWebp from "../../assets/media/sea-logo.webp";
import shopeeLogoPng from "../../assets/media/shopee-logo.png";
import shopeeLogoWebp from "../../assets/media/shopee-logo.webp";
import bytedanceLogoPng from "../../assets/media/bytedance-logo.png";
import bytedanceLogoWebp from "../../assets/media/bytedance-logo.webp";
import stripeLogoPng from "../../assets/media/stripe-logo.png";
import stripeLogoWebp from "../../assets/media/stripe-logo.webp";
import UndergraduateTaSection from "../undergraduate-ta-section";
import { PDF_VIEWER_PATH } from "../../constants";
import DateRangeBadge from "../date-range-badge/date-range-badge";

export const STRIPE_LINK = "https://stripe.com";
const BYTEDANCE_LINK = "https://www.bytedance.com";
const SHOPEE_LINK = "https://shopee.sg";
const SEA_LINK = "https://www.sea.com";

function ExperienceSection() {
  return (
    <div className="vertical-space-margin wide">
      <div>
        <h3>
          <strong>Full-time</strong>
        </h3>

        <ListGroup flush>
          <ListGroupItem>
            <ListGroupItemHeading>
              Software Engineer @{" "}
              <a href={STRIPE_LINK} target="_blank" rel="noopener noreferrer">
                <picture>
                  <source srcSet={stripeLogoWebp} type="image/webp" />
                  <img className="mini-logo" alt="Stripe" src={stripeLogoPng} />
                </picture>
              </a>{" "}
              <DateRangeBadge start={new Date(2022, 5)} />
            </ListGroupItemHeading>
          </ListGroupItem>

          <div />
        </ListGroup>
      </div>

      <div>
        <h3>
          <strong>Internship</strong>
        </h3>

        <ListGroup flush>
          <ListGroupItem>
            <ListGroupItemHeading>
              Software Engineer Intern @{" "}
              <a
                href={BYTEDANCE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source srcSet={bytedanceLogoWebp} type="image/webp" />
                  <img
                    className="tiny-logo"
                    alt="ByteDance"
                    src={bytedanceLogoPng}
                  />
                </picture>
              </a>{" "}
              <DateRangeBadge
                start={new Date(2021, 11)}
                end={new Date(2022, 1)}
              />
            </ListGroupItemHeading>
            <ListGroupItemText>
              • Joined the Global Payments backend team.
            </ListGroupItemText>
            <ListGroupItemText>
              • Implemented and deployed a cron job to facilitate automated
              testing of payment transactions in development environment.
            </ListGroupItemText>
            <ListGroupItemText>
              • Designed and built a utility package to facilitate file transfer
              among cloud services, internal cloud object storage and external
              bank payment channels.
            </ListGroupItemText>
            <ListGroupItemText>
              • Integrated external bank payment channels API with
              ByteDance&apos;s existing payment services.
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Software Engineer Intern @{" "}
              <a href={SHOPEE_LINK} target="_blank" rel="noopener noreferrer">
                <picture>
                  <source srcSet={shopeeLogoWebp} type="image/webp" />
                  <img
                    className="small-logo"
                    alt="Shopee"
                    src={shopeeLogoPng}
                  />
                </picture>
              </a>{" "}
              <DateRangeBadge
                start={new Date(2021, 4)}
                end={new Date(2021, 6)}
              />
            </ListGroupItemHeading>
            <ListGroupItemText>
              • Joined the Shopee web frontend team.
            </ListGroupItemText>
            <ListGroupItemText>
              • Designed and implemented an{" "}
              <a
                href="https://github.com/JermyTan/eventology"
                target="_blank"
                rel="noopener noreferrer"
              >
                event management web app
              </a>
              .
            </ListGroupItemText>
            <ListGroupItemText>
              • Improved the UI/UX of the internal API collaboration web app.
            </ListGroupItemText>
            <ListGroupItemText>
              • In charge of the migration of Shopee web app features to micro
              frontend.
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href={`${PDF_VIEWER_PATH}?pdf=shopee_internship_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Certificate</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Software Engineer Intern @{" "}
              <a href={SEA_LINK} target="_blank" rel="noopener noreferrer">
                <picture>
                  <source srcSet={seaLogoWebp} type="image/webp" />
                  <img className="small-logo" alt="Sea" src={seaLogoPng} />
                </picture>
              </a>{" "}
              <DateRangeBadge
                start={new Date(2020, 4)}
                end={new Date(2020, 7)}
              />
            </ListGroupItemHeading>
            <ListGroupItemText>
              • Joined the{" "}
              <a
                href="https://seatalk.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                SeaTalk
              </a>{" "}
              web frontend team.
            </ListGroupItemText>
            <ListGroupItemText>
              • Designed and implemented an open-source{" "}
              <a
                href="https://github.com/JermyTan/giffindor"
                target="_blank"
                rel="noopener noreferrer"
              >
                GIF search web app
              </a>
              .
            </ListGroupItemText>
            <ListGroupItemText>
              • Built a progressive web app that functions as a bug report
              viewer for internal use. It is able to decrypt, parse and render
              log files of up to 5 million lines of text. It also supports graph
              visualization of CPU/memory usages data.
            </ListGroupItemText>
            <ListGroupItemText>
              • Improved screenshot tool&rsquo;s startup time performance on an
              existing product by 10x faster (new startup time is ~200ms).
            </ListGroupItemText>
          </ListGroupItem>
        </ListGroup>

        <div />
      </div>

      <UndergraduateTaSection />
    </div>
  );
}

export default memo(ExperienceSection);
