import { Route, Routes, Navigate } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./app.scss";
import { Container, Row, Col } from "reactstrap";
import useFullscreen from "./custom-hooks/useFullscreen";
import Header from "./components/header";
import ExperienceSection from "./components/experience-section";
import AcademicsSection from "./components/academics-section";
import PortfolioSection from "./components/portfolio-section";
import SocialMediaSection from "./components/social-media-section";
import NavigationButtons from "./components/navigation-buttons";
import PdfViewerSection from "./components/pdf-viewer-section";
import FullscreenButton from "./components/fullscreen-button";
import VideoPlayerSection from "./components/video-player-section";
import {
  ACADEMICS_PATH,
  EXPERIENCE_PATH,
  PORTFOLIO_PATH,
  PDF_VIEWER_PATH,
  VIDEO_PLAYER_PATH,
} from "./constants";

function App() {
  const { isFullscreen } = useFullscreen();

  return (
    <>
      {!isFullscreen && <Header />}
      <div className={isFullscreen ? "pb-4" : "section"}>
        <Container fluid={isFullscreen}>
          {isFullscreen ? (
            <div className="d-flex justify-content-end">
              <FullscreenButton />
            </div>
          ) : (
            <>
              <SocialMediaSection />

              <Row>
                <Col className="d-flex justify-content-center">
                  <NavigationButtons />
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col>
              <Routes>
                <Route path={EXPERIENCE_PATH} element={<ExperienceSection />} />
                <Route path={ACADEMICS_PATH} element={<AcademicsSection />} />
                <Route path={PORTFOLIO_PATH} element={<PortfolioSection />} />
                <Route path={PDF_VIEWER_PATH} element={<PdfViewerSection />} />
                <Route
                  path={VIDEO_PLAYER_PATH}
                  element={<VideoPlayerSection />}
                />
                <Route
                  path="*"
                  element={<Navigate to={EXPERIENCE_PATH} replace />}
                />
              </Routes>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default App;
