import { Pdf } from "../components/pdf-viewer";

const REGEX_URL_ORIGIN = /^https?:\/\/[^/]+/g;
const REGEX_URL_ENDS_WITH_FILE = /[^/]+$/g;
const REVERSE_PROXY_URL = "https://jeremy-cors-anywhere-server.herokuapp.com";

export function getPdfFromUrl(search: string): Pdf | undefined {
  const pdfQuery = new URLSearchParams(search).get("pdf");

  if (!pdfQuery) {
    return undefined;
  }

  const name = pdfQuery.match(REGEX_URL_ENDS_WITH_FILE)?.[0] ?? "download.pdf";

  const pdfUrlOrigin = pdfQuery.match(REGEX_URL_ORIGIN)?.[0];

  const data =
    pdfUrlOrigin === window.location.origin
      ? pdfQuery
      : `${
          pdfUrlOrigin ? REVERSE_PROXY_URL : window.location.origin
        }/${pdfQuery}`;

  return { name, data };
}

export function getVideoFromUrl(search: string): string | undefined {
  const videoQuery = new URLSearchParams(search).get("video");

  if (!videoQuery) {
    return undefined;
  }

  const videoUrlOrigin = videoQuery.match(REGEX_URL_ORIGIN)?.[0];

  return videoUrlOrigin
    ? videoQuery
    : `${window.location.origin}/${videoQuery}`;
}
