import { useState, useEffect } from "react";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Collapse,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./navigation-buttons.scss";
import {
  ACADEMICS_PATH,
  EXPERIENCE_PATH,
  PORTFOLIO_PATH,
  PDF_VIEWER_PATH,
  VIDEO_PLAYER_PATH,
} from "../../constants";
import FullscreenButton from "../fullscreen-button";

const HIDE_HIDDEN_TABS = "Hide hidden tabs";
const SHOW_HIDDEN_TABS = "Show hidden tabs";

const hiddenPaths = [PDF_VIEWER_PATH, VIDEO_PLAYER_PATH];

const TooltipContent = ({
  isShowingHiddenContent,
  scheduleUpdate,
}: {
  isShowingHiddenContent: boolean;
  scheduleUpdate: () => void;
}) => {
  const [text, setText] = useState(SHOW_HIDDEN_TABS);

  useEffect(() => {
    setText(isShowingHiddenContent ? HIDE_HIDDEN_TABS : SHOW_HIDDEN_TABS);
    scheduleUpdate();
  }, [isShowingHiddenContent, scheduleUpdate]);

  return <>{text}</>;
};

function NavigationButtons() {
  const { pathname } = useLocation();
  const [isShowingHiddenContent, setShowingHiddenContent] = useState(false);

  useEffect(() => {
    if (hiddenPaths.some((path) => pathname.startsWith(path))) {
      setShowingHiddenContent(true);
    }
  }, [pathname]);

  return (
    <div className="navigation-buttons">
      <Button
        tag="a"
        block
        className="action-button"
        href={`${PDF_VIEWER_PATH}?pdf=jeremy_resume.pdf&fullscreen=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="far fa-file-user" /> Resume
      </Button>

      <div className="nav-align-center nav-container">
        <Nav
          className="nav-pills-info nav-pills-just-icons nav-item-container"
          pills
        >
          <NavItem>
            <NavLink
              tag={Link}
              to={EXPERIENCE_PATH}
              active={pathname.startsWith(EXPERIENCE_PATH)}
            >
              <i className="now-ui-icons tech_laptop" />
              <span className="category text-black">Experience</span>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={Link}
              to={ACADEMICS_PATH}
              active={pathname.startsWith(ACADEMICS_PATH)}
            >
              <i className="now-ui-icons education_hat" />
              <span className="category text-black">Academics & Skills</span>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={Link}
              to={PORTFOLIO_PATH}
              active={pathname.startsWith(PORTFOLIO_PATH)}
            >
              <i className="now-ui-icons education_paper" />
              <span className="category text-black">Portfolio</span>
            </NavLink>
          </NavItem>
        </Nav>

        <Collapse isOpen={isShowingHiddenContent}>
          <Nav
            className="nav-pills-info nav-pills-just-icons nav-item-container"
            pills
          >
            <NavItem>
              <NavLink
                tag={Link}
                to={PDF_VIEWER_PATH}
                active={pathname.startsWith(PDF_VIEWER_PATH)}
              >
                <i className="far fa-file-pdf" />
                <span className="category text-black">PDF Viewer</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                tag={Link}
                to={VIDEO_PLAYER_PATH}
                active={pathname.startsWith(VIDEO_PLAYER_PATH)}
              >
                <i className="far fa-play-circle" />
                <span className="category text-black">Video Player</span>
              </NavLink>
            </NavItem>
          </Nav>

          <FullscreenButton className="action-button" showTooltip={false}>
            Fullscreen
          </FullscreenButton>
        </Collapse>
      </div>

      <div
        className="hidden-trigger hover-bob"
        id="hidden-trigger"
        onClick={() => setShowingHiddenContent(!isShowingHiddenContent)}
      />
      <UncontrolledTooltip
        popperClassName="inverted-tooltip"
        delay={0}
        target="hidden-trigger"
      >
        {({ scheduleUpdate }) => (
          <TooltipContent
            isShowingHiddenContent={isShowingHiddenContent}
            scheduleUpdate={scheduleUpdate}
          />
        )}
      </UncontrolledTooltip>
    </div>
  );
}

export default NavigationButtons;
