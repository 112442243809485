import { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";

enum TabType {
  Languages = "LANGUAGES",
  FrameworksAndTools = "FRAMEWORKS_AND_TOOLS",
}

type Devicon = {
  displayName: string;
  name: string;
  type: string;
};

const languages: Devicon[] = [
  { displayName: "Python", name: "python", type: "plain" },
  { displayName: "TypeScript", name: "typescript", type: "plain" },
  { displayName: "JavaScript", name: "javascript", type: "plain" },
  { displayName: "Ruby", name: "ruby", type: "plain" },
  { displayName: "Java", name: "java", type: "plain" },
  { displayName: "Go", name: "go", type: "plain" },
  { displayName: "Swift", name: "swift", type: "plain" },
  { displayName: "R", name: "r", type: "plain" },
  { displayName: "C", name: "c", type: "plain" },
];

const frameworksAndTools: Devicon[] = [
  { displayName: "React", name: "react", type: "plain" },
  { displayName: "HTML5", name: "html5", type: "plain" },
  { displayName: "CSS3", name: "css3", type: "plain" },
  { displayName: "Sass", name: "sass", type: "plain" },
  { displayName: "Node.js", name: "nodejs", type: "plain" },
  { displayName: "django", name: "django", type: "plain" },
  { displayName: "Electron", name: "electron", type: "original" },
  { displayName: "Docker", name: "docker", type: "plain" },
  { displayName: "NGINX", name: "nginx", type: "plain" },
  { displayName: "PostgreSQL", name: "postgresql", type: "plain" },
  { displayName: "Babel", name: "babel", type: "plain" },
  { displayName: "Redux", name: "redux", type: "plain" },
  { displayName: "Firebase", name: "firebase", type: "plain" },
  { displayName: "Bootstrap", name: "bootstrap", type: "plain" },
  { displayName: "Travis CI", name: "travis", type: "plain" },
  { displayName: "Gradle", name: "gradle", type: "plain" },
  { displayName: "Git", name: "git", type: "plain" },
  { displayName: "GitHub", name: "github", type: "plain" },
  { displayName: "GitLab", name: "gitlab", type: "plain" },
  { displayName: "Photoshop", name: "photoshop", type: "plain" },
];

function SkillsSection() {
  const [activeTab, setActiveTab] = useState<TabType>(TabType.Languages);

  return (
    <Card>
      <CardHeader>
        <Nav className="nav-tabs-info justify-content-center" tabs justified>
          <NavItem>
            <NavLink
              tag="div"
              active={activeTab === TabType.Languages}
              onClick={() => setActiveTab(TabType.Languages)}
            >
              <strong>Languages</strong>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag="div"
              active={activeTab === TabType.FrameworksAndTools}
              onClick={() => setActiveTab(TabType.FrameworksAndTools)}
            >
              <strong>Frameworks & Tools</strong>
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      <CardBody>
        <TabContent activeTab={activeTab} className="text-center">
          <TabPane tabId={TabType.Languages}>
            <Row className="tools">
              {languages.map(({ displayName, name, type }) => (
                <Col key={name} xs="3">
                  <i className={`devicon-${name}-${type} colored icon-text`}>
                    <span className="text">{displayName}</span>
                  </i>
                </Col>
              ))}
            </Row>
          </TabPane>

          <TabPane tabId={TabType.FrameworksAndTools}>
            <Row className="tools">
              {frameworksAndTools.map(({ displayName, name, type }) => (
                <Col key={name} xs="3">
                  <i className={`devicon-${name}-${type} colored icon-text`}>
                    <span className="text">{displayName}</span>
                  </i>
                </Col>
              ))}
            </Row>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
}

export default SkillsSection;
