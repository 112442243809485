import { ReactNode, useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import DateRangeBadge from "../date-range-badge/date-range-badge";

enum TabType {
  Timeline = "TIMELINE",
  Modules = "MODULES",
}

type TaModule = {
  title: string;
  descriptions: ReactNode[];
  period: { start: Date; end: Date };
};

type TimelineModule = {
  moduleTitles: string[];
  date: string;
};

const timelineModules: TimelineModule[] = [
  {
    moduleTitles: ["CS3216 Software Product Engineering for Digital Markets"],
    date: "Aug 2022 - Dec 2022",
  },
  {
    moduleTitles: [
      "CS3217 Software Engineering on Modern Application Platforms",
      "CS2040 Data Structures and Algorithms",
      "CS1010E Programming Methodology I",
      "ST1131 Introduction to Statistics and Statistical Computing",
    ],
    date: "Jan 2022 - May 2022",
  },
  {
    moduleTitles: [
      "CS3219 Software Engineering Principles and Patterns",
      "CS3216 Software Product Engineering for Digital Markets",
      "CS2040S Data Structures and Algorithms",
      "CS2040 Data Structures and Algorithms",
      "CS1010E Programming Methodology I",
    ],
    date: "Aug 2021 - Dec 2021",
  },
  {
    moduleTitles: ["CS2040 Data Structures and Algorithms"],
    date: "Jun 2021 - Jul 2021",
  },
  {
    moduleTitles: [
      "CS3217 Software Engineering on Modern Application Platforms",
      "CS2040S Data Structures and Algorithms",
      "CS2040 Data Structures and Algorithms",
    ],
    date: "Jan 2021 - May 2021",
  },
  {
    moduleTitles: [
      "CS3216 Software Product Engineering for Digital Markets",
      "CS2040 Data Structures and Algorithms",
      "CS2030 Programming Methodology II",
      "CS1010E Programming Methodology I",
    ],
    date: "Aug 2020 - Dec 2020",
  },
  {
    moduleTitles: ["CS1010E Programming Methodology I"],
    date: "Jun 2020 - Jul 2020",
  },
  {
    moduleTitles: [
      "CS2040S Data Structures and Algorithms",
      "CS2030 Programming Methodology II",
    ],
    date: "Jan 2020 - May 2020",
  },
  {
    moduleTitles: [
      "CS2040 Data Structures and Algorithms",
      "CS1231S Discrete Structures",
      "CS1010E Programming Methodology I",
    ],
    date: "Aug 2019 - Dec 2019",
  },
  {
    moduleTitles: [
      "CS2030 Programming Methodology II",
      "CS1010X Programming Methodology I",
    ],
    date: "Jan 2019 - May 2019",
  },
];

const taModules: TaModule[] = [
  {
    title: "CS3216 Software Product Engineering for Digital Markets",
    descriptions: [
      "Reviewed and improved course content and requirements.",
      "Graded and gave feedback on students' assignments.",
    ],
    period: { start: new Date(2020, 7), end: new Date(2022, 11) },
  },
  {
    title: "CS3217 Software Engineering on Modern Application Platforms",
    descriptions: [
      "Reviewed and improved course content and requirements.",
      "Graded and gave feedback on students' assignments.",
    ],
    period: { start: new Date(2021, 0), end: new Date(2022, 4) },
  },
  {
    title: "CS2040(S) Data Structures and Algorithms",
    descriptions: [
      "Conducted tutorial and programming laboratory sessions.",
      "Reinforced students’ knowledge on the design and implementation of fundamental data structures and algorithms.",
      "Graded and gave feedback on students’ problem sets.",
    ],
    period: { start: new Date(2019, 7), end: new Date(2022, 4) },
  },
  {
    title: "CS1010E Programming Methodology I",
    descriptions: [
      "Conducted tutorial sessions.",
      "Introduced python programming language and fundamental concepts of computational problem solving to students.",
      "Graded and gave feedback on students’ assignments.",
    ],
    period: { start: new Date(2019, 7), end: new Date(2022, 4) },
  },
  {
    title: "ST1131 Introduction to Statistics and Statistical Computing",
    descriptions: [
      "Facilitated group discussions during weekly tutorials.",
      "Graded and gave feedback on students’ tutorial submissions.",
    ],
    period: { start: new Date(2022, 0), end: new Date(2022, 4) },
  },
  {
    title: "CS3219 Software Engineering Principles and Patterns",
    descriptions: [
      "Conducted tutorial sessions.",
      "Reinforced students’ knowledge on the software engineering principles and patterns.",
      "Mentored 6 project groups and gave feedback on software architecture designs.",
    ],
    period: { start: new Date(2021, 7), end: new Date(2021, 11) },
  },
  {
    title: "CS2030(S) Programming Methodology II",
    descriptions: [
      "Conducted programming laboratory sessions.",
      "Enhanced students’ proficiency in Java.",
      "Reinforced object-oriented and functional programming competencies in students.",
    ],
    period: { start: new Date(2019, 0), end: new Date(2020, 11) },
  },
  {
    title: "CS1231S Discrete Structures",
    descriptions: [
      "Conducted tutorial sessions.",
      "Reviewed mathematical concepts taught in lectures.",
      "Graded and gave feedback on students’ assignments.",
    ],
    period: { start: new Date(2019, 7), end: new Date(2019, 11) },
  },
  {
    title: "CS1010X Programming Methodology I",
    descriptions: [
      "Mentored and monitored students’ progress in the module.",
      "Graded and gave feedback on students’ assignments.",
      <>
        Fixed bugs and updated contents on{" "}
        <a
          href="https://coursemology.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Coursemology
        </a>
        .
      </>,
    ],
    period: { start: new Date(2019, 0), end: new Date(2019, 4) },
  },
];

function UndergraduateTaSection() {
  const [activeTab, setActiveTab] = useState<TabType>(TabType.Timeline);

  return (
    <div>
      <h3>
        <strong>NUS Undergraduate Teaching Assistant</strong>
      </h3>

      <div className="vertical-space-margin wide">
        <Nav className="nav-tabs-info justify-content-center" tabs justified>
          <NavItem>
            <NavLink
              tag="div"
              active={activeTab === TabType.Timeline}
              onClick={() => setActiveTab(TabType.Timeline)}
            >
              <strong>Timeline</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="div"
              active={activeTab === TabType.Modules}
              onClick={() => setActiveTab(TabType.Modules)}
            >
              <strong>Modules</strong>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId={TabType.Timeline}>
            <VerticalTimeline className="vertical-timeline-custom-line">
              <VerticalTimelineElement
                date="Graduated in Dec 2022"
                iconStyle={{
                  backgroundColor: "#e91e63",
                }}
                icon={
                  <div className="icon-element">
                    <i className="now-ui-icons education_hat" />
                  </div>
                }
              />

              {timelineModules.map(({ moduleTitles, date }) => (
                <VerticalTimelineElement
                  key={date}
                  textClassName="content-container"
                  contentStyle={{
                    borderTopColor: "#2CA8FF",
                  }}
                  date={date}
                  iconStyle={{
                    backgroundColor: "#2CA8FF",
                  }}
                  icon={
                    <div className="icon-element">
                      <i className="now-ui-icons tech_laptop" />
                    </div>
                  }
                >
                  <ListGroup flush>
                    {moduleTitles.map((title) => (
                      <ListGroupItem key={title} className="px-0">
                        <strong>{title}</strong>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </VerticalTimelineElement>
              ))}

              <VerticalTimelineElement
                date="Matriculated in Aug 2018"
                iconStyle={{
                  backgroundColor: "#10cc52",
                }}
                icon={
                  <div className="icon-element">
                    <i className="now-ui-icons business_bank" />
                  </div>
                }
              />
            </VerticalTimeline>
          </TabPane>

          <TabPane tabId={TabType.Modules}>
            <div />

            <ListGroup flush>
              {taModules.map(({ title, descriptions, period }) => (
                <ListGroupItem key={title}>
                  <ListGroupItemHeading>
                    {title} <DateRangeBadge {...period} />
                  </ListGroupItemHeading>
                  {descriptions.map((description, index) => (
                    <ListGroupItemText key={description?.toString() ?? index}>
                      • {description}
                    </ListGroupItemText>
                  ))}
                </ListGroupItem>
              ))}
            </ListGroup>

            <div />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}

export default UndergraduateTaSection;
