import { ReactNode } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import useFullscreen from "../../custom-hooks/useFullscreen";

type Props = {
  className?: string;
  hideOnFullscreen?: boolean;
  children?: ReactNode;
  showTooltip?: boolean;
};

function FullscreenButton({
  className,
  hideOnFullscreen,
  children,
  showTooltip = true,
}: Props) {
  const { isFullscreen, setFullscreen } = useFullscreen();

  return hideOnFullscreen && isFullscreen ? null : (
    <>
      <Button
        className={className}
        id="toggle-fullscreen"
        onClick={() => setFullscreen(!isFullscreen)}
      >
        {isFullscreen ? (
          <i className="fas fa-compress" />
        ) : (
          <i className="fas fa-expand" />
        )}{" "}
        {children}
      </Button>
      {showTooltip && (
        <UncontrolledTooltip
          popperClassName="inverted-tooltip"
          delay={0}
          target="toggle-fullscreen"
        >
          {isFullscreen ? "Exit fullscreen" : "Fullscreen"}
        </UncontrolledTooltip>
      )}
    </>
  );
}

export default FullscreenButton;
