import { Badge, BadgeProps } from "reactstrap";
import {
  addMonths,
  format,
  formatDuration,
  intervalToDuration,
} from "date-fns";

type Props = {
  start: Date;
  end?: Date;
} & Omit<BadgeProps, "children">;

const formatDate = (date: Date) => format(date, "MMM yyyy");

function DateRangeBadge({ start, end, ...props }: Props) {
  const startString = formatDate(start);
  const endString = end ? formatDate(end) : "Present";
  const durationString = formatDuration(
    intervalToDuration({ start, end: addMonths(end ?? new Date(), 1) }),
    { format: ["years", "months"] },
  );
  return (
    <Badge {...props}>
      {`${startString} - ${endString} ( ${durationString} )`}
    </Badge>
  );
}

export default DateRangeBadge;
