import { useEffect, useState, useRef } from "react";
import { Accept } from "react-dropzone";
import { useNavigate, useLocation } from "react-router-dom";
import { usePreviousDistinct } from "react-use";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import { getVideoFromUrl } from "../../utils";
import FileUploader from "../file-uploader";
import VideoPlayer from "../video-player";
import "./video-player-section.scss";

const acceptedFileTypes: Accept = {
  "video/*": [],
};

function VideoPlayerSection() {
  const { search, pathname } = useLocation();
  const previousSearch = usePreviousDistinct(search);
  const [video, setVideo] = useState<string | undefined>(
    getVideoFromUrl(search),
  );

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const previousSearchParams = new URLSearchParams(previousSearch);
    const newVideo = getVideoFromUrl(search);

    if (!previousSearchParams.has("video") !== !newVideo) {
      setVideo(newVideo);
    }
  }, [search, previousSearch]);

  const onAcceptVideoFile = (files: File[]) => {
    const videoFile = files?.[0];

    if (!videoFile) {
      return;
    }

    const video = URL.createObjectURL(videoFile);

    setVideo(video);
  };

  return video ? (
    <VideoPlayer video={video} />
  ) : (
    <FileUploader
      className="video-uploader"
      onDropAccepted={onAcceptVideoFile}
      icon={<i className="far fa-file-video fa-3x" />}
      title="Enter URL, drag and drop, or click here to upload video file."
      accept={acceptedFileTypes}
      extra={
        <Form
          className="url-input-form"
          onSubmit={(e) => {
            e.preventDefault();

            const input = inputRef.current;

            if (!input?.value) {
              return;
            }

            const searchParams = new URLSearchParams({
              [input.name]: input.value,
            });

            navigate(`${pathname}?${searchParams}`);
          }}
        >
          <InputGroup
            className="url-input-group"
            onClick={(e) => e.stopPropagation()}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fal fa-link" />
              </InputGroupText>
            </InputGroupAddon>

            <Input innerRef={inputRef} name="video" placeholder="Video URL" />

            <InputGroupAddon addonType="append">
              <Button type="submit">Upload</Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      }
    />
  );
}

export default VideoPlayerSection;
