import { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function useFullscreen() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const isFullscreen = searchParams.get("fullscreen") === "1";

    const setFullscreen = (newValue: boolean) => {
      if (isFullscreen === newValue) {
        return;
      }

      if (newValue) {
        searchParams.set("fullscreen", "1");
      } else {
        searchParams.delete("fullscreen");
      }

      navigate(`${pathname}?${searchParams}`);
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    };

    return { isFullscreen, setFullscreen };
  }, [pathname, search, navigate]);
}

export default useFullscreen;
