import { memo } from "react";
import { Row, Col } from "reactstrap";
import GradesSection from "../grades-section";
import SkillsSection from "../skills-section";

function AcademicsSection() {
  return (
    <Row>
      <Col className="mx-auto" md="10" lg="6">
        <GradesSection />
      </Col>

      <Col className="mx-auto" md="10" lg="6">
        <SkillsSection />
      </Col>
    </Row>
  );
}

export default memo(AcademicsSection);
