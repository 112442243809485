import { memo } from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import treeckleLogoPng from "../../assets/media/treeckle-logo.png";
import treeckleLogoWebp from "../../assets/media/treeckle-logo.webp";
import gameOfRunesLogoPng from "../../assets/media/game-of-runes-logo.png";
import gameOfRunesLogoWebp from "../../assets/media/game-of-runes-logo.webp";
import peggleIslandLogoPng from "../../assets/media/peggle-island-logo.png";
import peggleIslandLogoWebp from "../../assets/media/peggle-island-logo.webp";
import xpireLogoPng from "../../assets/media/xpire-logo.png";
import xpireLogoWebp from "../../assets/media/xpire-logo.webp";
import carinaLogoPng from "../../assets/media/carina-logo.png";
import carinaLogoWebp from "../../assets/media/carina-logo.webp";
import orbitalApolloLogoPng from "../../assets/media/orbital-apollo-logo.png";
import orbitalApolloLogoWebp from "../../assets/media/orbital-apollo-logo.webp";
import shopeeUltraHackathon2021LogoPng from "../../assets/media/shopee-ultra-hackathon-2021.png";
import shopeeUltraHackathon2021LogoWebp from "../../assets/media/shopee-ultra-hackathon-2021.webp";
import hacknroll2021Logo from "../../assets/media/hacknroll-2021-logo.svg";
import shopeeCodeLeague2020LogoPng from "../../assets/media/shopee-code-league-2020.png";
import shopeeCodeLeague2020LogoWebp from "../../assets/media/shopee-code-league-2020.webp";
import hacknroll2020Logo from "../../assets/media/hacknroll-2020-logo.svg";
import arcticLakeLogoPng from "../../assets/media/arctic-lake-logo.png";
import arcticLakeLogoWebp from "../../assets/media/arctic-lake-logo.webp";
import metaLogoPng from "../../assets/media/meta-logo.png";
import metaLogoWebp from "../../assets/media/meta-logo.webp";
import angelhackLogoPng from "../../assets/media/angelhack-logo.png";
import angelhackLogoWebp from "../../assets/media/angelhack-logo.webp";
import { PDF_VIEWER_PATH } from "../../constants";

function PortfolioSection() {
  return (
    <div className="vertical-space-margin wide portfolio-section">
      <div>
        <h3>
          <strong>Projects</strong>
        </h3>

        <ListGroup flush>
          <ListGroupItem>
            <ListGroupItemHeading>
              Treeckle{" "}
              <picture>
                <source srcSet={treeckleLogoWebp} type="image/webp" />
                <img alt="" src={treeckleLogoPng} className="small-logo" />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              A student life helper web app for Residential Colleges in NUS.
              <br />
              Designed and implemented the end-to-end technology stack for
              Treeckle. Piloted Treeckle’s venue booking system in CAPT, one of
              NUS’s residential colleges. Liaised with CAPT’s College Students’
              Committee to propose future development and financing of Treeckle.
              Currently, it is being used by over 600 CAPT residents.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-react-plain colored icon-text">
                  <span className="text">React</span>
                </i>
                <i className="devicon-redux-plain colored icon-text">
                  <span className="text">Redux</span>
                </i>
                <i className="devicon-typescript-plain colored icon-text">
                  <span className="text">TypeScript</span>
                </i>
                <i className="devicon-django-plain colored icon-text">
                  <span className="text">django</span>
                </i>
                <i className="devicon-postgresql-plain colored icon-text">
                  <span className="text">PostgreSQL</span>
                </i>
                <i className="devicon-docker-plain colored icon-text">
                  <span className="text">Docker</span>
                </i>
                <i className="devicon-nginx-plain colored icon-text">
                  <span className="text">NGINX</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href="https://youtu.be/TeCNkYFiCh4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Video</strong>
              </a>
              <br />
              <a
                href="https://treeckle.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Website</strong>
              </a>
              <br />
              <a
                href="https://github.com/CAPTxTreeckle/Treeckle-3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Project repository</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Game Of Runes{" "}
              <picture>
                <source srcSet={gameOfRunesLogoWebp} type="image/webp" />
                <img alt="" src={gameOfRunesLogoPng} className="small-logo" />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              A gesture-based iPad game where the player attempts to destroy
              incoming enemies through swiping gestures.
              <br />
              Designed the game&rsquo;s UI/UX and implemented both the single
              player and multiplayer frontend. Integrated backend network
              interface with frontend components. Enhanced game design with
              animations, BGMs and sound effects.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-swift-plain colored icon-text">
                  <span className="text">Swift</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href="https://youtu.be/8g098szZv7U"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Video</strong>
              </a>
              <br />
              <a
                href="https://github.com/CS3217-Final-Project/TeamHoWan"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Project repository</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Peggle Island{" "}
              <picture>
                <source srcSet={peggleIslandLogoWebp} type="image/webp" />
                <img alt="" src={peggleIslandLogoPng} className="small-logo" />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              An iPad Peggle clone game where the player has to clear the pegs
              in the level by firing a limited number of balls.
              <br />
              Built a level design interface where players can create and
              customise their very own levels. Designed and implemented the
              physics and game engine from scratch. Extended the game to include
              bonus features such as theming, power-ups, score system, level
              previews, animations, BGMs and sound effects.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-swift-plain colored icon-text">
                  <span className="text">Swift</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              Peggle Island was featured in{" "}
              <a
                href="https://www.youtube.com/watch?v=rg3o0iczLAg&t=180s"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>CS3217 AY19/20 Hall of Fame</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Xpire{" "}
              <picture>
                <source srcSet={xpireLogoWebp} type="image/webp" />
                <img alt="" src={xpireLogoPng} className="small-logo" />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              A desktop expiry date tracker.
              <br />
              Implemented QR code export functionality, built and integrated new
              API with existing components. Managed product releases on GitHub,
              set up project site and introduced continuous integration to the
              project.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-java-plain colored icon-text">
                  <span className="text">Java</span>
                </i>
                <i className="devicon-travis-plain colored icon-text">
                  <span className="text">Travis CI</span>
                </i>
                <i className="devicon-gradle-plain colored icon-text">
                  <span className="text">Gradle</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href="https://ay1920s1-cs2103t-f11-2.github.io/main/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Website</strong>
              </a>
              <br />
              <a
                href="https://github.com/AY1920S1-CS2103T-F11-2/main"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Project repository</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Carina{" "}
              <picture>
                <source srcSet={carinaLogoWebp} type="image/webp" />
                <img alt="" src={carinaLogoPng} className="small-logo" />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              A progressive web app that functions as a carpark availability
              locator.
              <br />
              Implemented authentication and integrated support for 3rd party
              authentication providers, such as Facebook login and Google
              sign-in. Designed and built the offline functionality of Carina.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-react-plain colored icon-text">
                  <span className="text">React</span>
                </i>
                <i className="devicon-typescript-plain colored icon-text">
                  <span className="text">TypeScript</span>
                </i>
                <i className="devicon-java-plain colored icon-text">
                  <span className="text">Java</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href="https://github.com/JermyTan/Carina"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Project repository</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>
        </ListGroup>

        <div />
      </div>

      <div>
        <h3>
          <strong>Achievements</strong>
        </h3>

        <ListGroup flush>
          <ListGroupItem>
            <ListGroupItemHeading>
              NUS School of Computing Dean&rsquo;s List
            </ListGroupItemHeading>
            <ListGroupItemText>
              •{" "}
              <a
                href={`${PDF_VIEWER_PATH}?pdf=dean's_list_ay2021_sem2_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>AY20-21 Semester 2</strong>
              </a>
            </ListGroupItemText>
            <ListGroupItemText>
              •{" "}
              <a
                href={`${PDF_VIEWER_PATH}?pdf=dean's_list_ay2021_sem1_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>AY20-21 Semester 1</strong>
              </a>
            </ListGroupItemText>
            <ListGroupItemText>• AY19-20 Semester 1</ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Bachelor of Computing (CS) Focus Area Awards
            </ListGroupItemHeading>
            <ListGroupItemText>
              •{" "}
              <a
                href={`${PDF_VIEWER_PATH}?pdf=networking_and_distributed_systems_focus_area_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>
                  Certificate of Distinction in the Networking and Distributed
                  Systems Focus Area
                </strong>
              </a>
            </ListGroupItemText>
            <ListGroupItemText>
              •{" "}
              <a
                href={`${PDF_VIEWER_PATH}?pdf=software_engineering_focus_area_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>
                  Certificate of Distinction in the Software Engineering Focus
                  Area
                </strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Commendation Letter for Outstanding Performance
            </ListGroupItemHeading>
            <ListGroupItemText>
              •{" "}
              <a
                href={`${PDF_VIEWER_PATH}?pdf=cs4222_commendation_letter.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>CS4222 Wireless Networking</strong>
              </a>
            </ListGroupItemText>
            <ListGroupItemText>
              •{" "}
              <a
                href={`${PDF_VIEWER_PATH}?pdf=cs4226_commendation_letter.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>CS4226 Internet Architecture</strong>
              </a>
            </ListGroupItemText>
            <ListGroupItemText>
              •{" "}
              <a
                href={`${PDF_VIEWER_PATH}?pdf=cs3217_commendation_letter.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>
                  CS3217 Software Engineering on Modern Application Platforms
                </strong>
              </a>
            </ListGroupItemText>
            <ListGroupItemText>
              •{" "}
              <a
                href={`${PDF_VIEWER_PATH}?pdf=cs1010x_commendation_letter.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>CS1010X Programming Methodology I</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>Orbital</ListGroupItemHeading>
            <ListGroupItemText>
              <a
                href="https://orbital.comp.nus.edu.sg/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Orbital
              </a>{" "}
              is NUS School of Computing&rsquo;s summer independent software
              development course where 1st year students work in pairs to
              complete a software project of their choice using an iterative
              software development life cycle.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong>2023 Mentor (Artemis - Extreme)</strong>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href={`${PDF_VIEWER_PATH}?pdf=orbital_mentor_2023_appreciation_letter.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Letter of Appreciation</strong>
              </a>
            </ListGroupItemText>
            <ListGroupItemText>
              <strong>2022 Mentor (Artemis - Extreme)</strong>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href={`${PDF_VIEWER_PATH}?pdf=orbital_mentor_2022_appreciation_letter.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Letter of Appreciation</strong>
              </a>
            </ListGroupItemText>
            <ListGroupItemText>
              <strong>2019 Participant (Apollo 11 - Advanced)</strong>{" "}
              <picture>
                <source srcSet={orbitalApolloLogoWebp} type="image/webp" />
                <img alt="" src={orbitalApolloLogoPng} className="small-logo" />
              </picture>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href={`${PDF_VIEWER_PATH}?pdf=orbital_2019_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Certificate</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>
        </ListGroup>

        <div />
      </div>

      <div>
        <h3>
          <strong>Hackathons</strong>
        </h3>

        <ListGroup flush>
          <ListGroupItem>
            <ListGroupItemHeading>
              AngelHack Monthly Code Challenge May 2023{" "}
              <picture>
                <source srcSet={angelhackLogoWebp} type="image/webp" />
                <img alt="" src={angelhackLogoPng} className="medium-logo" />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              A 2-week coding challenge consisting of 7 algorithmic problems.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-python-plain colored icon-text">
                  <span className="text">Python</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <div>Emerged as overall 3rd place 🥉.</div>
              <div className="ml-4">Prize 🏆: USD 500</div>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href="https://github.com/JermyTan/AngelHack-Monthy-Code-Challenge-May-2023"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Submission repository</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Arctic Lake Coding Challenge 2022{" "}
              <picture>
                <source srcSet={arcticLakeLogoWebp} type="image/webp" />
                <img alt="" src={arcticLakeLogoPng} className="mini-logo" />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              A 1-week coding competition consisting of 4 questions where each
              submission is graded on code execution speed and efficiency.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-java-plain colored icon-text">
                  <span className="text">Java</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <div>Emerged as overall 3rd place 🥉.</div>
              <div className="ml-4">Prize 🏆: 1x Nintendo Switch OLED</div>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Meta Global Hackathon 2022{" "}
              <picture>
                <source srcSet={metaLogoPng} type="image/webp" />
                <img alt="" src={metaLogoWebp} className="medium-logo" />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              A 2-week online hackathon consisting of Coding Challenge, Linux
              Challenge, Find the Bug Challenge and many more.
            </ListGroupItemText>
            <ListGroupItemText>
              <div>
                Emerged as Global 2nd place 🥈 under Early Career category.
              </div>
              <div className="ml-4">
                Prizes 🏆🏆🏆: 1x Oculus Meta Quest 2, 1x Meta Portal Go, 1x
                Ray-Ban Stories Wayfarer, 1x Meta Hoodie, 1x Meta T-shirt
              </div>
            </ListGroupItemText>
            <ListGroupItemText>
              <div>
                Received Fastest Coder award ⚡️ under Early Career category.
              </div>
              <div className="ml-4">Prize 🏆: 1x Meta Apparel</div>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href={`${PDF_VIEWER_PATH}?pdf=meta_global_hackathon_2022_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Certificate</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Shopee Ultra Hackathon 2021{" "}
              <picture>
                <source
                  srcSet={shopeeUltraHackathon2021LogoWebp}
                  type="image/webp"
                />
                <img
                  alt=""
                  src={shopeeUltraHackathon2021LogoPng}
                  className="small-logo"
                />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              Designed, built and integrated a &lsquo;Guess the price&rsquo;
              feature onto the Shopee App in 36 hours. Users are given the
              opportunity to guess the price of an item. If the price they
              guessed is lower than the original price, some discounts may be
              applied onto the item. The guessed prices data can be tracked and
              feedback to the sellers for data-driven analysis.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-react-plain colored icon-text">
                  <span className="text">React</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href={`${PDF_VIEWER_PATH}?pdf=ultra_hackathon_2021_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Certificate</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Hack&Roll 2021{" "}
              <img alt="" src={hacknroll2021Logo} className="small-logo" />
            </ListGroupItemHeading>
            <ListGroupItemText>
              Built a mini game web app in 24 hours. The game requires the
              player to repeatedly click on a balloon for as many times as
              possible within a fixed time period. There are also different game
              modes with varying difficulty to push players to their limits.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-react-plain colored icon-text">
                  <span className="text">React</span>
                </i>
                <i className="devicon-typescript-plain colored icon-text">
                  <span className="text">TypeScript</span>
                </i>
                <i className="devicon-firebase-plain colored icon-text">
                  <span className="text">Firebase</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href="https://click-click-balloon.web.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Website</strong>
              </a>
              <br />
              <a
                href="https://github.com/YeetCoders/click-click-balloon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Project repository</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Shopee Code League 2020{" "}
              <picture>
                <source
                  srcSet={shopeeCodeLeague2020LogoWebp}
                  type="image/webp"
                />
                <img
                  alt=""
                  src={shopeeCodeLeague2020LogoPng}
                  className="small-logo"
                />
              </picture>
            </ListGroupItemHeading>
            <ListGroupItemText>
              A 2-month online coding challenge consisting of a series of
              competitions, online algorithm questions and online training
              workshops open to all students and professionals across the
              region.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-python-plain colored icon-text">
                  <span className="text">Python</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href={`${PDF_VIEWER_PATH}?pdf=code_league_2020_cert.pdf&fullscreen=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Certificate</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
            <ListGroupItemHeading>
              Hack&Roll 2020{" "}
              <img alt="" src={hacknroll2020Logo} className="small-logo" />
            </ListGroupItemHeading>
            <ListGroupItemText>
              Built an augmented reality photo filter in 24 hours. Integrated
              8th Wall framework with HTML5 to allow users to plant fun objects
              in the environment and take creative photos which they can save to
              their phones. Since it is a web app, it is cross-platform and no
              app download is necessary.
            </ListGroupItemText>
            <ListGroupItemText>
              <strong className="text-info">Tech Stack:</strong>
              <span className="tools flex-wrap d-flex justify-content-start">
                <i className="devicon-html5-plain colored icon-text">
                  <span className="text">HTML5</span>
                </i>
                <i className="devicon-css3-plain colored icon-text">
                  <span className="text">CSS3</span>
                </i>
                <i className="devicon-javascript-plain colored icon-text">
                  <span className="text">JavaScript</span>
                </i>
              </span>
            </ListGroupItemText>
            <ListGroupItemText>
              <a
                href="https://github.com/The-Farmers/ar-app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Project repository</strong>
              </a>
            </ListGroupItemText>
          </ListGroupItem>
        </ListGroup>

        <div />
      </div>
    </div>
  );
}

export default memo(PortfolioSection);
