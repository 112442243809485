import { useRef, useEffect, useState, memo } from "react";
import { Button, Container } from "reactstrap";
import ReactCardFlip from "react-card-flip";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import backgroundWebp from "../../assets/media/background.webp";
import backgroundJpg from "../../assets/media/background.jpg";
import jeremyWebp from "../../assets/media/jeremy.webp";
import jeremyJpg from "../../assets/media/jeremy.jpg";
import jeremyMemeWebp from "../../assets/media/jeremy-meme.webp";
import jeremyMemeJpg from "../../assets/media/jeremy-meme.jpg";
import "./header.scss";

function Header() {
  const headerImage = useRef<HTMLElement>(null);
  const [isFlipped, setFlipped] = useState(false);
  const [isSpecialContentActive, setSpecialContentActive] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    const updateScroll = () => {
      if (!headerImage.current) {
        return;
      }
      const windowScrollTop = window.pageYOffset / 3;
      headerImage.current.style.transform = `translateY(${windowScrollTop}px)`;
    };
    updateScroll();
    window.addEventListener("scroll", updateScroll);
    return () => window.removeEventListener("scroll", updateScroll);
  }, []);

  return (
    <>
      {isSpecialContentActive && (
        <Confetti
          height={height}
          width={width}
          numberOfPieces={1000}
          gravity={0.2}
          tweenDuration={10000}
          recycle={false}
        />
      )}

      {/* eslint-disable-next-line react/no-unknown-property */}
      <div className="page-header clear-filter" filter-color="blue">
        <picture
          className="page-header-image-container parallax-container"
          ref={headerImage}
        >
          <source srcSet={backgroundWebp} type="image/webp" />
          <img className="page-header-image" alt="" src={backgroundJpg} />
        </picture>
        <Container>
          <div
            className="hover-bob"
            onClick={() => {
              setFlipped(!isFlipped);

              if (isFlipped && isSpecialContentActive) {
                setTimeout(() => setSpecialContentActive(false), 500);
              }
            }}
          >
            <ReactCardFlip
              isFlipped={isFlipped}
              flipSpeedBackToFront={0.6}
              flipSpeedFrontToBack={0.6}
            >
              <div className="photo-container">
                <picture>
                  <source srcSet={jeremyWebp} type="image/webp" />
                  <img alt="" src={jeremyJpg} />
                </picture>
              </div>

              <div className="photo-container">
                {isSpecialContentActive ? (
                  <picture>
                    <source srcSet={jeremyMemeWebp} type="image/webp" />
                    <img alt="" src={jeremyMemeJpg} />
                  </picture>
                ) : (
                  <div className="pt-3 hidden-content">
                    <h3 className="mb-1">Congratulations</h3>
                    <p className="mb-0">You have found me!</p>
                    <Button
                      className="mb-0 btn-round"
                      size="sm"
                      color="primary"
                      onClick={(e) => {
                        setSpecialContentActive(true);
                        e.stopPropagation();
                      }}
                    >
                      <strong>Get prize</strong>
                    </Button>
                  </div>
                )}
              </div>
            </ReactCardFlip>
          </div>

          <h3 className="title">Tan Kai Qun, Jeremy</h3>
          <p className="category">Software Engineer @ Stripe</p>
          {/* <p className="subtitle">National University of Singapore</p> */}
        </Container>
      </div>
    </>
  );
}

export default memo(Header);
