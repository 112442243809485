import { ReactNode } from "react";
import clsx from "clsx";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import "./file-uploader.scss";

type Props = {
  className?: string;
  icon?: ReactNode;
  title?: string;
  description?: string;
  extra?: ReactNode;
} & DropzoneOptions;

function FileUploader({
  className,
  icon = <i className="far fa-file-alt fa-3x" />,
  title = "Drag and drop, or click here to upload file.",
  description,
  extra = null,
  multiple = false,
  ...dropzoneOptions
}: Props) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      multiple,
      ...dropzoneOptions,
    });

  return (
    <div
      {...getRootProps({
        className: clsx(
          "upload-container",
          "vertical-space-margin",
          {
            "focused-upload": isFocused,
            "accepted-upload": isDragAccept,
            "rejected-upload": isDragReject,
          },
          className,
        ),
      })}
    >
      <input {...getInputProps()} />
      <div className="upload-title-container">
        {icon}
        <div className="upload-title">{title}</div>
      </div>

      {description && <p className="upload-description">{description}</p>}

      {extra}
    </div>
  );
}

export default FileUploader;
