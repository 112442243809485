import { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import "./grades-section.scss";

enum TabType {
  Gpa = "GPA",
  Transcript = "TRANSCRIPT",
}

const Grade = {
  "A+": 5,
  A: 5,
  "A-": 4.5,
  S: 0,
};

type Module = {
  code: string;
  name: string;
  credits: number;
};

type GradeList = {
  grade: keyof typeof Grade;
  modules: Module[];
};

const transcript: GradeList[] = [
  {
    grade: "A+",
    modules: [
      {
        code: "CS4226",
        name: "Internet Architecture",
        credits: 4,
      },
      {
        code: "CS4222",
        name: "Wireless Networking",
        credits: 4,
      },
      { code: "CS3245", name: "Information Retrieval", credits: 4 },
      {
        code: "CS3219",
        name: "Software Engineering Principles and Patterns",
        credits: 4,
      },
      {
        code: "CS3217",
        name: "Software Engineering on Modern Application Platforms",
        credits: 5,
      },
      { code: "CS2106", name: "Introduction to Operating Systems", credits: 4 },
      { code: "CS2102", name: "Database Systems", credits: 4 },
      { code: "CS2040", name: "Data Structures and Algorithms", credits: 4 },
      { code: "CS1010X", name: "Programming Methodology I", credits: 4 },
      { code: "ST2137", name: "Computer Aided Data Analysis", credits: 4 },
      { code: "ST1131", name: "Introduction to Statistics", credits: 4 },
      { code: "LSM1301", name: "General Biology", credits: 4 },
    ],
  },
  {
    grade: "A",
    modules: [
      {
        code: "CS5332",
        name: "Biometric Authentication",
        credits: 4,
      },
      {
        code: "CS4218",
        name: "Software Testing",
        credits: 4,
      },
      {
        code: "CS3244",
        name: "Machine Learning",
        credits: 4,
      },
      {
        code: "CS3230",
        name: "Design and Analysis of Algorithms",
        credits: 4,
      },
      {
        code: "CS3216",
        name: "Software Product Engineering for Digital Markets",
        credits: 5,
      },
      { code: "CS2105", name: "Introduction to Computer Networks", credits: 4 },
      { code: "CS2103T", name: "Software Engineering", credits: 4 },
      { code: "CS2100", name: "Computer Organisation", credits: 4 },
      { code: "CS2030", name: "Programming Methodology II", credits: 4 },
      { code: "CS1231", name: "Discrete Structures", credits: 4 },
      { code: "ST3131", name: "Regression Analysis", credits: 4 },
      { code: "ST2131", name: "Probability", credits: 4 },
      {
        code: "MA2311",
        name: "Techniques in Advanced Calculus",
        credits: 4,
      },
      {
        code: "IS1103",
        name: "IS Innovations in Organisations and Society",
        credits: 4,
      },
    ],
  },
  {
    grade: "A-",
    modules: [
      {
        code: "CP4101",
        name: "B.Comp. Dissertation",
        credits: 12,
      },
      {
        code: "CS2107",
        name: "Introduction to Information Security",
        credits: 4,
      },
    ],
  },
  {
    grade: "S",
    modules: [
      {
        code: "CP3202",
        name: "Internship II",
        credits: 6,
      },
      {
        code: "CP3200",
        name: "Internship",
        credits: 6,
      },
      {
        code: "CP2106",
        name: "Independent Software Development Project (Orbital)",
        credits: 4,
      },
      {
        code: "CS2101",
        name: "Effective Communication for Computing Professionals",
        credits: 4,
      },
      {
        code: "ES2660",
        name: "Communicating in the Information Age",
        credits: 4,
      },
      { code: "ST2132", name: "Mathematical Statistics", credits: 4 },
      { code: "MA1101R", name: "Linear Algebra I", credits: 4 },
      { code: "MA1102R", name: "Calculus", credits: 4 },
      {
        code: "UTC2720",
        name: "Income Inequality: A Teleological Perspective",
        credits: 4,
      },
      { code: "UTS2404", name: "Cities and nature", credits: 4 },
      {
        code: "UTW2001R",
        name: "Discourse, Citizenship, and Society",
        credits: 4,
      },
      {
        code: "UTC1417",
        name: "Jr Sem: Bioethics in the 21st Century",
        credits: 4,
      },
      {
        code: "UTW1001W",
        name: "The Online Politician: The Use of Social Media in Political Communication",
        credits: 4,
      },

      { code: "GER1000", name: "Quantitative Reasoning", credits: 4 },
      { code: "CFG1002", name: "Career Catalyst", credits: 2 },
    ],
  },
];

const gpa = (() => {
  const numerator = transcript.reduce(
    (sum, gradeList) =>
      sum +
      (gradeList.grade === "S"
        ? 0
        : gradeList.modules.reduce(
            (totalGradePoints, module) =>
              totalGradePoints + module.credits * Grade[gradeList.grade],
            0,
          )),
    0,
  );
  const denominator = transcript.reduce(
    (sum, gradeList) =>
      sum +
      (gradeList.grade === "S"
        ? 0
        : gradeList.modules.reduce(
            (totalCredits, module) => totalCredits + module.credits,
            0,
          )),
    0,
  );

  return numerator / denominator;
})();

const gradesOverview = transcript
  .filter(({ grade }) => grade !== "S")
  .map(({ grade, modules }) => `${modules.length} ${grade}`)
  .join(", ")
  .replace(/,\s([^,]+)$/, " and $1");

const transcriptContent = transcript.map(({ grade, modules }, index) => (
  <div key={grade}>
    <h4 className={index === 0 ? "mt-0" : undefined}>{grade} modules</h4>

    <ListGroup flush>
      {modules.map(({ code, name }) => (
        <ListGroupItem key={code}>
          <a
            href={`https://nusmods.com/modules/${code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {code}
          </a>
          <br />
          {name}
        </ListGroupItem>
      ))}
    </ListGroup>

    <div />
  </div>
));

function GradesSection() {
  const [activeTab, setActiveTab] = useState<TabType>(TabType.Gpa);

  return (
    <Card className="grades-section">
      <CardHeader>
        <Nav className="nav-tabs-info justify-content-center" tabs justified>
          <NavItem>
            <NavLink
              tag="div"
              active={activeTab === TabType.Gpa}
              onClick={() => setActiveTab(TabType.Gpa)}
            >
              <strong>GPA</strong>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag="div"
              active={activeTab === TabType.Transcript}
              onClick={() => setActiveTab(TabType.Transcript)}
            >
              <strong>Transcript</strong>
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      <CardBody className="content-container">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TabType.Gpa}>
            <h3 className="mb-2">Bachelor of Computing in Computer Science</h3>
            <h5 className="mt-2">
              Honours (Highest Distinction) <br />
              National University of Singapore (NUS)
            </h5>
            <p className="gpa-badge-container mb-0">
              <Badge>GPA {gpa.toFixed(2)}</Badge>
            </p>
            <p>GPA out of 5</p>
            <p className="grades-overview">{gradesOverview}</p>
          </TabPane>

          <TabPane tabId={TabType.Transcript}>{transcriptContent}</TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
}

export default GradesSection;
