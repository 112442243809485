import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import GaPageViewManager from "./components/ga-page-view-manager";

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
} else {
  console.warn("Google Analytics is not enabled.");
}

const container = document.getElementById("root");

if (!container) {
  throw new Error("Root element not found");
}

const root = createRoot(container);

root.render(
  <StrictMode>
    <Router>
      <GaPageViewManager />
      <App />
    </Router>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
