import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Accept } from "react-dropzone";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Form,
  Button,
} from "reactstrap";
import useFullscreen from "../../custom-hooks/useFullscreen";
import PdfViewer, { Pdf } from "../pdf-viewer";
import FileUploader from "../file-uploader";
import { getPdfFromUrl } from "../../utils";
import "./pdf-viewer-section.scss";

const acceptedFileTypes: Accept = {
  "application/pdf": [".pdf"],
};

function PdfViewerSection() {
  const { search, pathname } = useLocation();
  const { setFullscreen } = useFullscreen();
  const [pdf, setPdf] = useState<Pdf | undefined>(getPdfFromUrl(search));
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPdf((pdf) => {
      const newPdf = getPdfFromUrl(search);

      if (pdf?.data instanceof File && !newPdf) {
        return pdf;
      }

      return JSON.stringify(pdf) === JSON.stringify(newPdf) ? pdf : newPdf;
    });
  }, [search]);

  const onAcceptPdfFile = (files: File[]) => {
    const pdfFile = files?.[0];

    if (!pdfFile) {
      return;
    }

    const { name } = pdfFile;
    setPdf({ name, data: pdfFile });
    setFullscreen(true);
  };

  return pdf ? (
    <PdfViewer pdf={pdf} />
  ) : (
    <FileUploader
      className="pdf-uploader"
      onDropAccepted={onAcceptPdfFile}
      icon={<i className="far fa-file-pdf fa-3x" />}
      title="Enter URL, drag and drop, or click here to upload PDF file."
      accept={acceptedFileTypes}
      extra={
        <Form
          className="url-input-form"
          onSubmit={(e) => {
            e.preventDefault();

            const input = inputRef.current;

            if (!input?.value) {
              return;
            }

            const searchParams = new URLSearchParams({
              [input.name]: input.value,
              fullscreen: "1",
            });

            navigate(`${pathname}?${searchParams}`);
          }}
        >
          <InputGroup
            className="url-input-group"
            onClick={(e) => e.stopPropagation()}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fal fa-link" />
              </InputGroupText>
            </InputGroupAddon>

            <Input innerRef={inputRef} name="pdf" placeholder="PDF URL" />

            <InputGroupAddon addonType="append">
              <Button type="submit">Upload</Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      }
    />
  );
}

export default PdfViewerSection;
