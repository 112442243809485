import React from "react";
import clsx from "clsx";
import { Spinner } from "reactstrap";
import "./placeholder-wrapper.scss";

type Props = {
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
  loadingMessage?: string;
  showDefaultMessage?: boolean;
  defaultMessage?: string;
  placeholder?: boolean;
};

function PlaceholderWrapper({
  children = null,
  className,
  loading = false,
  loadingMessage,
  showDefaultMessage = false,
  defaultMessage,
  placeholder = false,
}: Props) {
  return loading || showDefaultMessage ? (
    <div className={clsx("placeholder-wrapper", { placeholder }, className)}>
      {loading && (
        <>
          <Spinner />
          {loadingMessage && <div className="message">{loadingMessage}</div>}
        </>
      )}
      {!loading && showDefaultMessage && defaultMessage && (
        <div className="message">{defaultMessage}</div>
      )}
    </div>
  ) : (
    <>{children}</>
  );
}

export default PlaceholderWrapper;
