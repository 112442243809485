export const EXPERIENCE_PATH = "/experience/";
export const ACADEMICS_PATH = "/academics/";
export const PORTFOLIO_PATH = "/portfolio/";
export const PDF_VIEWER_PATH = "/pdf-viewer/";
export const VIDEO_PLAYER_PATH = "/video-player/";
export const PATH_TITLE_MAPPING = {
  [EXPERIENCE_PATH]: "experience",
  [ACADEMICS_PATH]: "academics",
  [PORTFOLIO_PATH]: "portfolio",
  [PDF_VIEWER_PATH]: "pdf viewer",
  [VIDEO_PLAYER_PATH]: "video player",
} as Record<string, string | undefined>;
